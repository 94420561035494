import * as React from "react"
import Helmet from 'react-helmet';
import HomeHero from '../components/HomeHero';

const IndexPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>ShopMojo</title>
      </Helmet>
      <HomeHero />
    </>
  )
}

export default IndexPage
