import React from 'react';

import Footer from '../Footer';


import * as styles from './HomeHero.module.scss';


const Container: React.FC = () => {

  return (
    <div className={styles.root}>
      <img src="/logo.png" className={styles.logo} />
      <h3 className={styles.tagline}>
        We create Shopify apps to make your online store better.
      </h3>

      <Footer className={styles.footer}/>
    </div>
  );
}

export default Container;